.gift {
  &-form {
    .gift__upload {
      width: 100%;
      border-style: dashed;
    }
    .tx-left {
      text-align: end;
    }
    &-space {
      .ebs-space__item {
        width: 100%;
      }
    }
  }
  &__list {
    flex: 1;
    overflow: auto;
    height: 300px;
    border: none;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  &__item {
    transition: 0.25s ease-in-out;
    padding: 0;
    border-left: 3px solid transparent;
    cursor: pointer;
    &--active {
      color: $primary-color;
      border-left: 3px solid $primary-color;
    }
    &__wrapper {
      display: flex;
      padding: rem(16px);
    }
    &:hover {
      color: $primary-color;
      border-left: 3px solid $primary-color;
    }
  }
}

.eye-icon {
  font-size: 1.5em;
}
