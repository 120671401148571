.ebs-modal {
  overflow: hidden;
}
.category-delete-count {
  color: $danger-color;
  padding: 0 5px;
  font-size: rem(16px);
}

.categories {
  display: flex;
  width: 100%;
  margin-top: rem(20px);

  &__wrapper {
    margin-right: rem(30px);
    width: 100%;
    box-shadow: 0 1px 2px $grey-300;
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: #fff;
    max-width: 500px;
    border: 1px solid $grey-300;
    &:last-child {
      margin-right: 0;
    }
  }
  &__list {
    flex: 1;
    overflow: auto;
    height: 600px;
    border: none;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  &__item {
    transition: 0.25s ease-in-out;
    padding: 0;
    border-left: 3px solid transparent;
    cursor: pointer;
    &--active {
      color: $primary-color;
      border-left: 3px solid $primary-color;
    }
    &__wrapper {
      display: flex;
      padding: rem(16px);
    }
    &:hover {
      color: $primary-color;
      border-left: 3px solid $primary-color;
    }
  }
  &__add {
    background: #fff;
    .ebs-button {
      display: flex;
      align-items: center;
      justify-content: center;
      .ebs-icon {
        margin: 0 rem(14px) 0 0;
      }
    }
    .ebs-space__item {
      width: 100%;
      padding: rem(15px);
    }

    &__button {
      border-style: dashed;
      width: 100%;
      padding: rem(15px);
    }
  }
}
.no-category {
  line-height: rem(34px);
}
.faq__list-item {
  border-bottom: 1px solid $grey-300;
}
