// Fixed button loading styles
.ebs-button__prefix .ebs-loader__spinner--small {
  border: 2px solid #ffffff;
  border-top: 2px solid $primary-color;
}

.ebs-checkbox {
  width: 1.2rem;
  height: 1.2rem;
  padding: 0.2rem;
  border-radius: 4px;
}

.ebs-chips {
  background-color: white;
}

.ebs-action__tooltip-icon {
  color: $primary-color;
}

.ebs-sidebar__text {
  font-weight: 500;
}

.ebs-modal {
  overflow: hidden;
}

.ebs-textarea {
  resize: vertical;
  max-height: 500px;
}

.ebs-collapse__header__toggle {
  .ebs-icon--arrow-top {
    transform: rotateZ(90deg);
  }
}

.ebs-chips {
  background-color: white;
}

.ebs-action__tooltip-icon {
  color: $primary-color;
}

.ebs-layout__content-wrapper {
  background-color: $text-color-light;
}

.ebs-tooltip__trigger {
  cursor: pointer;
}

.ebs-button__action .ebs-button {
  border-width: 0;
  line-height: 1;
  padding: rem(6px) rem(7px);
  display: flex;
}

html,
body {
  font-weight: 400;
}

@for $i from 1 through 6 {
  h#{$i} {
    font-weight: 500;
  }
}

html {
  background-color: $layout-content-background;
}

.react-simple-image-viewer__modal {
  position: fixed;
  z-index: 100 !important;
  background-color: rgba(0, 0, 0, 0.8);
}

.ebs-avatar__inline--light .ebs-avatar__inline-alt {
  color: $text-color;
}

.ebs-avatar {
  box-shadow: none;
}

.ebs-label {
  font-weight: 500;
  &--fill#{&}--success {
    background-color: #d7f6cb !important;
    color: #4b7556 !important;
  }
  &--fill#{&}--danger {
    background-color: #f7baba !important; // because of ebs-design
    color: #754b4b !important;
  }
  &--fill#{&}--warning {
    background-color: #f8f7b1 !important;
    color: #75724b !important;
  }
}

.ebs-card {
  overflow: initial;
  border-radius: $border-radius;
  background-color: $white;

  &__header,
  &__body,
  &__footer {
    background-color: transparent;
  }
}

.ebs-select__wrapper.active.ebs-select--multiple .ebs-select-value {
  padding-right: 0;
}

.ebs-select-value__container {
  margin: 0;
}

.ebs-select-label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0;
}

.ebs-select-value__container > *,
.ebs-select-value__container > .ebs-select-label {
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
}

.ebs-select__options-item {
  border-radius: 0;
  margin: 0;
}

.ebs-select__options
  > .ebs-select__options-items
  .ebs-select__options-item:not(:last-child) {
  margin: 0;
}

.ebs-select__options-items {
  padding: 0;
  max-height: 250px !important;
}

.ebs-form__field__label.align-items--center.justify-content--start {
  align-items: flex-start !important;
}

.upload__container {
  margin-top: 0;
  margin-bottom: 4px;
}

.ebs-loader {
  z-index: inherit;
}
