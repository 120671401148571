.tutorials {
  &__tutorial {
    display: flex;
    font-weight: 500;
  }
  &__collapse {
    border-color: $grey-300;
    &__header {
      font-weight: 500;
      align-items: center;
    }
  }
  &__add-tutorial {
    display: table;
    margin: 20px 0 20px auto;
  }
  &__btn {
    margin: 0 rem(5px);
  }
  .ebs-collapse__header__toggle {
    background-color: transparent;
    border: 1px solid $primary-color;
    border-radius: rem(7px);
    align-items: center;
    padding: rem(0 8px 0 10px);
    margin: 0 0 0 rem(5px);
    height: 32px;
    transition: 0.25s ease-in-out;
    .ebs-icon {
      color: $primary-color;
    }
    &:hover {
      background-color: $primary-color;
      .ebs-icon {
        color: #fff;
      }
    }
  }
}
