.divider {
  display: block;
  width: 100%;
  border-bottom: 1px solid $grey-300;
}

.color-primary {
  color: $primary-color;
}

.color-secondary {
  color: $text-color-secondary;
}

.color-danger {
  color: $danger-color;
}

.small-text {
  font-size: 0.9em;
}

.cursor-pointer {
  cursor: pointer;
}

// rtitle = title to right side
.with-title[title],
.with-rtitle {
  position: relative;
}

.with-title[title],
.with-rtitle[title] {
  &:hover::after {
    content: attr(title);
    display: flex;
    position: absolute;
    top: 50%;
    right: calc(100% + 0.25rem);
    transform: translateY(-50%);
    background-color: #333;
    padding: 0.5rem 0.75rem;
    color: white;
    border-radius: $border-radius;
    pointer-events: none;
    z-index: 2;
    box-shadow: $box-shadow;
    white-space: nowrap;
  }
}

.with-rtitle[title]:hover:after {
  right: initial;
  left: calc(100% + 0.25rem);
}

.nowrap {
  white-space: nowrap;
}

.hidden {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.flex-1 {
  flex: 1;
}

@for $i from 1 through 16 {
  $coef: 0.25rem;

  .pad-#{$i} {
    padding: $i * $coef;
  }
  .padx-#{$i} {
    padding-left: $i * $coef;
    padding-right: $i * $coef;
  }
  .pady-#{$i} {
    padding-top: $i * $coef;
    padding-bottom: $i * $coef;
  }

  .mar-#{$i} {
    margin: $i * $coef;
  }
  .marx-#{$i} {
    margin-left: $i * $coef;
    margin-right: $i * $coef;
  }
  .mary-#{$i} {
    margin-top: $i * $coef;
    margin-bottom: $i * $coef;
  }

  .grid-gap-#{$i} {
    gap: $i * $coef;
  }
}

@for $i from 1 through 9 {
  .fw-#{$i}00 {
    font-weight: $i * 100;
  }
}

@for $i from 2 through 5 {
  .grid-#{$i}cols {
    display: grid;
    grid-template-columns: repeat($i, 1fr);
  }
}

// Fixed button loading styles
.ebs-button__prefix .ebs-loader__spinner--small {
  border: 2px solid #ffffff;
  border-top: 2px solid $primary-color;
}

.link-color {
  color: $text-color;
}

.chip {
  display: inline-block;
  padding: 0.5em 1.5em;
  border-radius: 2em;
  background-color: white;
  border: 1px solid $grey-400;
  box-shadow: $box-shadow;

  &--sm {
    padding: 0.25em 0.75em;
    border-radius: 1em;
  }
  &--md {
    //
  }
  &--lg {
    //
  }
}

.border-dashed {
  border-style: dashed;
}

.full-width {
  width: 100%;
}

.no-items {
  border: 1px solid $grey-300;
  background-color: #fff;
  box-shadow: 0 1px 2px $grey-300;
  border-radius: 0.5rem;
  padding: rem(20px);
  margin: rem(20px) 0 0;
}

.center-text {
  text-align: center;
}

.rotate-90 {
  transform: rotate(90deg);
}

.display-grid {
  display: grid;
}

.bg-grey-100 {
  background-color: $grey-100;
}
.bg-grey-200 {
  background-color: $grey-200;
}
.bg-grey-300 {
  background-color: $grey-300;
}
.bg-grey-400 {
  background-color: $grey-400;
}
.bg-grey-500 {
  background-color: $grey-500;
}
.bg-grey-600 {
  background-color: $grey-600;
}
.bg-grey-700 {
  background-color: $grey-700;
}
.bg-grey-800 {
  background-color: $grey-800;
}
.bg-grey-900 {
  background-color: $grey-900;
}

.normalized-pre {
  word-break: break-word;
  white-space: pre-wrap;
}

@for $i from 1 to 20 {
  .max-width-#{$i} {
    max-width: $i * 2rem;
  }
  .min-width-#{$i} {
    min-width: $i * 2rem;
  }
}

.flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.cell-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
