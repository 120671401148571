.sliders {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-gap: 15px;
    margin: 20px 0 0;
  }
  &__add-sliders {
    margin: 20px 0 0;
    display: table;
    margin-left: auto;
  }
  &__image {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  &__card-img {
    width: 100px;
    object-fit: contain;
  }
  &__card {
    display: grid;
    grid-template-rows: 1fr auto;
    .ebs-card__body {
      overflow-y: auto;
    }
  }
}
