.details-card {
  padding: 2rem;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: 0.1s;
  background-color: #fff;
  border: 1px solid $grey-300;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    width: 3px;
    background-color: $primary-color;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    transform: scale(1.05);
  }

  &__grid {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  &__grid-2cols {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media (max-width: 1350px) {
  .details-card__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 470px) {
  .details-card__grid {
    grid-template-columns: 1fr;
  }
  .details-card__grid-2cols {
    grid-template-columns: 1fr;
  }
}

.product-card {
  height: 120px;
  width: 350px;
  display: grid;
  grid-template-columns: 160px 1fr auto;
  gap: 1rem;
  place-items: center stretch;
  border-radius: $border-radius;
  padding: 0.5rem;
  background-color: white;
  color: $text-color;
  border: 1px solid $grey-200;
  transition: 0.2s;

  &__brand {
    overflow: hidden;
    border-radius: $border-radius;
    place-self: center;

    img {
      width: 3rem;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__image {
    overflow: hidden;
    height: calc(100% - 1rem);
    border-radius: $border-radius;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 2rem;
    max-height: 500px;
    overflow-y: auto;
  }
}

.product-stats-card-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 720px) {
  .product-stats-card-details-grid {
    grid-template-columns: 1fr;
  }
}
