.user-profile-tooltip {
  &__item {
    @include flex-center-vert;
    color: $text-color;
    font-size: rem(12px);
    font-weight: 500;
    padding: rem(10px 15px 10px 5px);
    cursor: pointer;

    &:first-child {
      padding-top: rem(5px);
    }

    &:last-child {
      padding-bottom: rem(5px);
    }

    svg {
      margin-right: rem(15px);
    }
  }
  &__item:hover {
    color: $primary-color;
  }
}
