.router-tabs {
  border: 1px solid $grey-300;
  background-color: #fff;
  box-shadow: 0 1px 2px $grey-300;
  border-radius: 0.5rem;

  & + .ebs-tabs__content {
    padding: 0;
  }
  .ebs-tabs__item {
    padding: rem(20px 40px);
    border-right: solid 1px $grey-300;
    margin: 0;
    font-weight: 500;

    &.active {
      color: $primary-color;
      border-bottom: none;
    }
  }
}
