.cohorts {
  &__form-layout {
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-template-rows: 550px;
    gap: 2rem;
  }

  &__details-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 350px;
    gap: 1rem;
  }

  &__view-parameters-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
  }

  &__card-fix {
    display: grid;
    grid-template-rows: auto 1fr auto;
    .ebs-card__body {
      overflow-y: auto;
    }
  }

  &__tabs-container {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  &__tabs {
    border-bottom: 1px solid $grey-400;
    display: flex;
    z-index: 2;
    overflow-x: auto;
  }

  &__tab {
    padding: 1.5rem 2.5rem;
    border-bottom: none !important;
    margin-right: 0px;
    font-weight: 500;
    cursor: pointer;

    &:not(:last-child) {
      border-right: 1px solid $grey-400;
    }

    &.active {
      color: $primary-color;
    }
  }

  &__parameters-layout {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 1fr));
    grid-template-rows: 1fr;
    padding: 0;
    overflow-x: auto;
  }

  &__parameters-tabs {
    overflow-y: auto;
  }

  &__parameters-tab {
    padding: 1rem;
    margin: 0.25rem;
    background-color: white;
    border-radius: $border-radius;
    border: 1px solid $grey-400;
    cursor: pointer;
    transition: 0.12s;

    &:hover {
      color: $primary-color;
    }

    &.active {
      color: $primary-color;
      border-left: 2px solid $primary-color;
    }
  }

  &__parameters-column {
    background-color: white;
    margin: 0.25rem 0.25rem 0.25rem 0;
    padding: 1.5rem 1rem;
    overflow-y: auto;
    border-radius: $border-radius;
    border: 1px solid $grey-400;
  }

  &__parameters-column-span {
    grid-column: 2 / 4;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }

  &__overflow-fix {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
