.faq {
  display: grid;
  grid-template-columns: rem(350px) 1fr;
  grid-gap: rem(25px);
  .ebs-alert {
    margin: rem(20px);
  }
  &__question {
    display: flex;
    font-weight: 500;
  }

  &__btn-space {
    width: 100%;
    padding: rem(10px);
    border-top: 1px solid $grey-300;
    .ebs-space__item {
      width: 100%;
    }
  }
  &__add {
    border-style: dashed;
    width: 100%;
    max-width: rem(330px);
    margin: 0 auto;
    display: block;
    .ebs-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem(20px);
      .ebs-icon {
        margin: 0 rem(14px) 0 0;
      }
    }
  }
  &__no-question {
    padding: rem(22px) 1.42857rem;
    border-bottom: 1px solid $grey-300;
    font-weight: 500;
  }
  &__container {
    border: 1px solid $grey-300;
    background-color: #fff;
    box-shadow: 0 1px 2px $grey-300;
    border-radius: 0.5rem;
    overflow: hidden;

    &-list {
      border: none;
      border-radius: 0.5rem;
      overflow: auto;
      height: calc(100vh - 250px);
    }
  }
  &__collapse {
    border-left: none;
    border-right: none;
    border-top: none;
    box-shadow: none;
    border-radius: 0;
    &__header {
      font-weight: 500;
    }
  }
  &__list-item {
    padding: 0;
    &__wrapper {
      cursor: pointer;
    }
  }
  &__category {
    display: flex;
    justify-content: space-between;
    padding: rem(16px);
    border-left: 3px solid transparent;
    &:hover {
      border-left: 3px solid $primary-color;
    }
    &--active {
      border-left: 3px solid $primary-color;
    }
    &-controls {
      display: flex;
    }
    &-edit {
      margin: 0 rem(10px);
    }
    &-name {
      display: flex;
      align-items: center;
      transition: 0.2s ease-in-out;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
  &__question-body {
    font-family: inherit;
    white-space: pre-wrap;
    word-break: break-word;
    img {
      max-width: 100%;
    }
  }
  &__btn {
    margin: 0 rem(5px);
  }
  .ebs-collapse__header__toggle {
    background-color: transparent;
    border: 1px solid $primary-color;
    border-radius: rem(7px);
    align-items: center;
    padding: rem(0 8px 0 10px);
    margin: 0 0 0 rem(5px);
    min-height: rem(32px);
    .ebs-icon {
      color: $primary-color;
    }
    &:hover {
      background-color: $primary-color;
      .ebs-icon {
        color: #fff;
      }
    }
  }
}

.ql-toolbar {
  border-top-right-radius: rem(7px);
  border-top-left-radius: rem(7px);
  border-color: $grey-300;
}
.ql-container {
  height: 300px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  &.ql-snow {
    border-color: $grey-300;
  }
}
.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}
