.image-100x65 {
  max-width: 100px;
  min-width: 65px;
  height: 65px;
  overflow: hidden;

  img {
    @include image-styles();
  }
}

.image-70x50 {
  max-width: 70px;
  min-width: 50px;
  height: 50px;
  border: 1px solid $grey-300;
  overflow: hidden;

  img {
    @include image-styles();
  }
}

.image-50x35 {
  max-width: 50px;
  min-width: 35px;
  height: 35px;
  border: 1px solid $grey-300;
  overflow: hidden;

  img {
    @include image-styles();
  }
}

.icon-image {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;

  img {
    @include image-styles();
  }
}

.table-cell-image {
  position: absolute;
  padding: 0.5rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
