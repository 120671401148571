// Text colors
$heading-color: #444041;
$text-color: #444041;
$text-color-secondary: #788494;
$text-color-light: #f5f3f0;

//Font
$font-family: "Rubik", sans-serif;

$border-radius: 0.5rem;
$border-color: #cfd8dc;

$card-body-bg-color: #fff;
$background-content: #fff;

$box-shadow: none;
$layout-content-background: $text-color-light;
