.profile-card {
  padding: 2rem 2rem;
  border-radius: $border-radius;
  box-shadow: 0 2px 4px $grey-400;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  background-color: white;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    width: 3px;
    background-color: $primary-color;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  h1 {
    color: $primary-color;
  }

  &__link {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $primary-color;
    margin-bottom: 20px;

    &:hover {
      color: $primary-700;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 5rem;
  }

  &__icons {
    font-size: 50px;
    &--rotate {
      transform: rotate(-180deg);
      margin-right: 0px;
    }
  }
}

@media (max-width: 1200px) {
  .profile-card {
    &__grid {
      grid-template-columns: 1fr 40px 1fr;
    }
    &__icons {
      margin: 16px 0px 0;
      font-size: 35px;
    }
  }
}

@media (max-width: 992px) {
  .profile-card {
    &__grid {
      grid-template-columns: repeat(1, auto);
      gap: 0rem;
    }
    &__icons {
      display: grid;
      &--rotate {
        transform: rotate(-90deg);
      }
      .ebs-space__item {
        margin-left: 16px;
      }
    }
  }
}
