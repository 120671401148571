@import "./variables";
@import "./palette";

// Import ebs-design styles
@import "~ebs-design/dist/styles/index";

@import "ebs-design-override";

@import "mixins";

@import "common";
@import "merged-inputs";
@import "card";
@import "chart";
@import "table";
@import "settings";
@import "brands";
@import "auth";
@import "categories";
@import "404";
@import "pagination";
@import "filters";
@import "images";
@import "step";
@import "gifts";
@import "faq";
@import "userProfileTooltip";
@import "transactions";
@import "home";
@import "reorder";
@import "stats";
@import "product-image-list";
@import "cohorts";
@import "tutorials";
@import "gift-covers";
@import "tabs";
@import "error-boundary";
@import "select-list";
@import "sliders";
@import "face-filters";
@import "network-cards";
@import "campaigns";

body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
