.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  &__card {
    display: flex;
    width: 900px;
    background-color: #fff;
    border-radius: 16px;
    padding: 50px 0;
    @media screen and(max-width: 1024px) {
      margin: 0 20px;
    }
    @media screen and(max-width: 560px) {
      flex-direction: column;
      align-items: center;
    }
    &__box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 350px;
      @media screen and (max-width: 1024px) {
        width: 250px;
      }
      @media screen and(max-width: 560px) {
        margin: 0 0 20px;
      }
    }
    &__image {
      max-width: 100%;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    border-left: 1px solid #e5e5e5;
    @media screen and(max-width: 560px) {
      border: none;
    }
  }
}
