.merged-inputs {
  position: relative;
  display: inline-flex;
  align-items: stretch;

  border-radius: $border-radius;
  border: 1px solid $border-color;

  .separator {
    border-right: 1px solid $border-color;
  }

  &:focus-within {
    border-color: $primary-color;

    .separator {
      border-color: $primary-color;
    }
  }

  input {
    border: none;
    outline: none;

    padding: rem($input-padding-y) rem($input-padding-x);

    font-size: inherit;
    color: inherit;

    &:first-of-type {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-of-type {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .float-right {
    position: absolute;
    right: -0.25rem;
    top: 50%;
    transform: translate(100%, -50%);
  }
}
