.error-boundary {
  padding: 170px 0 0;
  text-align: center;
  &__title {
    font-size: 2.5em;
  }
  &__message {
    margin: 15px 0 20px 0;
    display: inline-flex;
  }
  &__try-again {
    display: table;
    margin: 0 auto;
  }
}
