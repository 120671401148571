.select-list {
  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: inline-block;
      transition: 0.2s;
      margin-right: 0.5em;
      width: 1em;
      height: 1em;
      border-radius: 0.3em;
      border: 1px solid $grey-500;
      flex-grow: 0;
      flex-shrink: 0;
    }
    &.active {
      // color: $primary-color;
      &:before {
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }
  }
}
