.circle-wrap {
  border: 1px solid #d0d0d0;
  border-radius: 50%;
  padding: 13px 14px 6px;
  font-size: 1.8rem;
  transition: 0.3s;

  &:hover {
    transform: scale(1.03);
    border: 1px solid $primary-color;
    z-index: 100;
  }
}

@media (max-width: 1399px) {
  .table-mt {
    margin-top: 1.5rem;
  }
}

.react-datepicker-popper.ebs-datepicker__popper .react-datepicker {
  flex-direction: column;
}

.date-picker-btn {
  width: 100px;
}

.datepicker-size {
  min-width: 200px;
}
