.reorder-box {
  color: $text-color;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 140, 165, 0.1);

  &__top,
  &__bottom {
    position: absolute;
    left: 0;
    height: calc(50% + 3px);
    width: 100%;
    opacity: 0;
    transition: 0.075s;
    border-color: $text-color;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
  &__top {
    top: -3px;
    border-top: 5px solid;
  }
  &__bottom {
    bottom: -3px;
    border-bottom: 5px solid;
  }

  &__arrow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
  }

  .ebs-icon {
    color: inherit;
    transform: rotateX(90deg);
    transition: 0.075s;
  }

  &__top:hover ~ &__arrow,
  &__bottom:hover ~ &__arrow {
    opacity: 1;
  }

  &__top:hover ~ &__arrow .ebs-icon {
    transform: translateY(-10px) rotateX(180deg);
  }
  &__bottom:hover ~ &__arrow .ebs-icon {
    transform: translateY(10px) rotateX(0deg);
  }
}
