.product-image-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 140px);
  grid-auto-rows: 100px;

  &__item {
    position: relative;
    display: inline-block;
    padding-right: 2rem;
    min-height: 5rem;
    height: 100%;
  }

  &__item-image {
    border: 1px solid $grey-300;
    border-radius: $border-radius;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__item-control {
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.25rem;
    padding: 0.25rem;
  }

  &__upload {
    height: 100%;
    width: 100%;
    padding-right: 2rem;
    & .ebs-button,
    & .ebs-button__wrapper {
      height: 100%;
      width: 100%;
    }
  }
}
